/* styles/accessibility.css */
.accessibility-mode * {
    font-family: sans-serif !important;
    font-size: 1.8rem !important;
    /* Увеличенный размер шрифта */
    line-height: 1.5 !important;
    /* Интерлиньяж для лучшей читаемости */
    color: black !important;
    /* Цвет текста */
    background: white !important;
    /* Цвет фона */
    filter: none !important;
    /* Убираем фильтры */
    border-color: black !important;
    /* Чёткие границы элементов */

    /* Убираем тени и прозрачность */
    /* box-shadow: none !important; */
    opacity: 1 !important;
}


.accessibility-mode body {
    font-size: 2rem !important;
    color: black !important;
    background-color: white !important;
    filter: none !important;
}

.accessibility-mode h1 {
    font-size: 3rem !important;
    /* Увеличенный размер для H1 */
    font-weight: bold !important;
    margin-bottom: 20px !important;
}

.accessibility-mode h2 {
    font-size: 3.6rem !important;
    /* Увеличенный размер для H2 */
    font-weight: bold !important;
    margin-bottom: 20px !important;
}

.accessibility-mode h3 {
    font-size: 3.2rem !important;
    /* Увеличенный размер для H3 */
    font-weight: bold !important;
    margin-bottom: 20px !important;
}

.accessibility-mode h4 {
    font-size: 4.8rem !important;
    /* Увеличенный размер для H4 */
    font-weight: bold !important;
    margin-bottom: 20px !important;
}

.accessibility-mode h5 {
    font-size: 4.6rem !important;
    /* Увеличенный размер для H5 */
    font-weight: bold !important;
    margin-bottom: 20px !important;
}

.accessibility-mode h6 {
    font-size: 4.4rem !important;
    /* Увеличенный размер для H6 */
    font-weight: bold !important;
    margin-bottom: 20px !important;
}


.accessibility-mode img,
.accessibility-mode video,
.accessibility-mode [style*="background-image"] {
    display: none !important;
    /* Скрываем изображения, видео и фоны */
}

.accessibility-mode button,
.accessibility-mode a:not(.festival-box_festivalItem__bEFQ_ > a,
    .things_thing_item__SD4nk >a,
    .attractionItems>div>div>a,
    .tourItem>div>a,
    .newsItem>div>a,
    .atractionBoxLink,
    .hotelItemBox>div>a,
    .entartaimentItem>div>a,
    .restaurantItem>div>a,
    .ulusItem>div>a) {
    background-color: black !important;
    color: white !important;
    padding: 15px 30px !important;
    border: 2px solid white !important;
    text-decoration: none !important;
    font-size: 1.5rem !important;
}

.accessibility-mode a:hover,
.accessibility-mode button:hover {
    background-color: white !important;
    color: black !important;
    border-color: black !important;
}

/* Добавляем видимые границы вокруг интерактивных элементов */
.accessibility-mode input,
.accessibility-mode select,
.accessibility-mode textarea,
.accessibility-mode button {
    border: 2px solid black !important;
    padding: 10px !important;
}

/* Обеспечиваем крупные отступы для всех блоков */
.accessibility-mode .ant-select-selector {
    margin: 0 15px !important;
}

.accessibility-mode .eventWrapper {
    font-size: 1.8rem !important;
    /* Увеличенный базовый размер текста */
    line-height: 1.6 !important;
}

.accessibility-mode .eventWrapper h2 {
    font-size: 2.4rem !important;
    /* Увеличенный размер заголовка */
}

.accessibility-mode .event_date {
    font-size: 1.6rem !important;
    /* Увеличенный размер текста даты */
    padding: 10px !important;
    border: 2px solid #000 !important;
    /* Четкая граница для выделения */
    background-color: #fff !important;
    color: #000 !important;
}

.accessibility-mode .eventWrapper p {
    font-size: 1.6rem !important;
    /* Увеличенный текст описания */
}

.accessibility-mode .loadingContainer {
    font-size: 2rem !important;
    /* Более крупные индикаторы загрузки */
}

.accessibility-mode .ant-spin-dot {
    font-size: 2rem !important;
    /* Увеличенный размер спиннера */
}

.accessibility-mode .ant-row {
    margin: 20px 0 !important;
    /* Увеличенные отступы между строками */
}

.accessibility-mode .festival-box {
    padding: 15px !important;
    border: 2px solid #000 !important;
    background-color: #fff !important;
    color: #000 !important;
    font-size: 1.6rem !important;
    margin-bottom: 20px !important;
}

.accessibility-mode .thingItem {
    flex: auto;
    max-width: 100%;
}

.accessibility-mode .newsImg {
    display: none;
}

.accessibility-mode .blogContent,
.accessibility-mode .aboutLeftContent,
.accessibility-mode .offSymbols {
    flex: auto;
    max-width: 100%;
}

.accessibility-mode .footerLogo {
    display: none !important;
}

.accessibility-mode .atractionBox>div,
.accessibility-mode .hotelItemBox>div,
.accessibility-mode .attractionItems>div>div,
.accessibility-mode .newsItem>div,
.accessibility-mode .restaurantItem>div,
.accessibility-mode .entartaimentItem>div,
.accessibility-mode .ulusItem>div {
    border-radius: 16px;
    border: 4px solid black !important;
    padding: 20px !important;
    background-color: #fff !important;
}

.accessibility-mode .ant-pagination>li {
    height: 50px !important;
}

.accessibility-mode .aboutImg,
.accessibility-mode .offSymbolsHide {
    display: none;
}