:root {
    --text-black: #000000;
    --text-white: #fff;
    --primary-green: #00704a;
    --secondary-green: #008080;
    --purple: #583e7b;
    --gray: #535353;
    --orange: #ea6d26;
    --red: #f05c59;
}

* {
    box-sizing: border-box;
}

a {
    color: unset;
}

a:hover {
    color: unset;
}

html,
body {
    margin: 0;
    padding: 0;
    position: relative;
}

.block {
    display: block;
}

.hidden {
    display: none;
}

.clear_bg {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    position: relative;
    background: url(/images/clear_ground.png);
}

.clear_bg.reverse {
    background: none;
}

.clear_bg.reverse::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(/images/clear_ground.png);
    transform: scaleX(-1);
    z-index: -1;
}

.w-full {
    width: 100%;
}

.section_bg_green {
    background:
        url('/images/backframe.png'),
        lightgray 50% / cover no-repeat;
    height: 420px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.section_bg_green.small {
    height: 365px;
}

.section_bg {
    background: url('/images/half_hed.png');
    height: 420px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.section_bg.small {
    height: 315px;
}

.select_wrapper .ant-select-selector {
    border-radius: 36px !important;
}

.search-modal .ant-modal-content {
    background: transparent !important;
    box-shadow: none !important;
}

.search-modal .ant-input-search {
    padding: 2rem 0;
    display: flex;
}

@media (max-width: 768px) {
    .section_bg.small {
        height: 160px;
    }

    .section_bg_green.small {
        height: 168px;
    }

    .section_bg {
        display: none;
    }

    .clear_bg.reverse::after {
        transform: scale(0.9);
        background-position: center;
    }
}

header .navSelect span {
    color: #fff;
}

header.fixed_black .navSelect span {
    color: var(--purple);
}

header.fixed_hotel .navSelect span {
    color: var(--purple);
}

header.black .navSelect span {
    color: var(--purple);
}











.accessible-mode {
    font-size: 1.5em !important;
    /* Увеличение шрифта */
    background-color: #000 !important;
    /* Высокий контраст */
    color: #fff !important;
    /* Высокий контраст текста */
    font-family: sans-serif !important;
    /* Другие стили, например: */
    animation: none !important;
    /* Отключение анимаций */
}

.accessible-mode a {
    color: yellow !important;
    background-color: #000 !important;
    /* Пример: изменение цвета ссылок для видимости */
}

.accessible-mode * {
    color: #fff !important;
    background-color: #000 !important;
    /* Пример: изменение цвета ссылок для видимости */
}

.accessible-mode a:hover {
    border: 1px solid #fff !important;
}

.up_arrow{
    /*opacity: 0;*/
    position: fixed;
    bottom: 90px;
    right: 5px;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    /*background-color: rgba(0, 0, 0, 0.5);*/
}

.up_arrow svg {
    cursor: pointer;
    opacity: 1;
}